import {Injectable} from '@angular/core';
import {VaccinationModel, VaccinationsConfigModel} from '@matchsource/models/vaccination';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {VaccinationControllerService} from '@matchsource/api-generated/subject';
import {fromDTO as vaccinationConfigModelFromDTO} from './vaccinations-config.serializer';
import {fromDTO as vaccinationModelFromDTO, toDTO as vaccinationModelToDTO} from './vaccination-model.serializer';
import {setSingleErrorCustomErrorHandlingContext, ClientErrorCode} from '@matchsource/error-handling/core';
import {FeatureService} from '@matchsource/feature-toggle';

import {FeatureToggleKey} from '@matchsource/core';

@Injectable({
  providedIn: 'root',
})
export class VaccinationsApiService {
  constructor(
    private readonly vaccinationControllerService: VaccinationControllerService,
    private readonly featureService: FeatureService
  ) {}

  private get isVaccinationFeatureEnabled(): boolean {
    return this.featureService.enabled(FeatureToggleKey.Vaccination);
  }

  getConfig(): Observable<VaccinationsConfigModel> {
    return this.vaccinationControllerService.getCatalog().pipe(map(dto => vaccinationConfigModelFromDTO(dto)));
  }

  getVaccinations(params: {sourceGuids: MsApp.Guid[]}): Observable<VaccinationModel[]> {
    if (!this.isVaccinationFeatureEnabled) {
      return of([]);
    }

    const context = setSingleErrorCustomErrorHandlingContext(ClientErrorCode.LoadingDonorVaccinationData);
    return this.vaccinationControllerService
      .getVaccinationsByGuid({body: params.sourceGuids}, {context: context()})
      .pipe(map(vaccinations => vaccinations.map(vaccination => vaccinationModelFromDTO(vaccination))));
  }

  updateVaccinations(params: {updatedVaccinations: VaccinationModel[]}): Observable<void> {
    const vaccinationDtos = params.updatedVaccinations.map(vaccination => vaccinationModelToDTO(vaccination));
    return this.vaccinationControllerService.updateVaccinationData({body: vaccinationDtos});
  }

  createVaccinations(params: {newVaccinations: VaccinationModel[]}): Observable<void> {
    const vaccinationDtos = params.newVaccinations.map(vaccination => vaccinationModelToDTO(vaccination));
    return this.vaccinationControllerService.createVaccinationData({body: vaccinationDtos});
  }
}
