import {MapTo} from '@matchsource/api-utils';
import {SupplementsStaticsDto} from '@matchsource/api-generated/subject';
import {VaccinationsConfigModel, VaccinationStatus, VaccineType} from '@matchsource/models/vaccination';

export const fromDTO: MapTo<SupplementsStaticsDto, VaccinationsConfigModel> = (
  dto: SupplementsStaticsDto
): VaccinationsConfigModel => ({
  vaccinationStatuses: dto.vaccinationStatuses as VaccinationStatus[],
  vaccineManufactures: dto.manufacturers,
  availableVaccinations: dto.classCodes.map(classCode => ({
    name: classCode as VaccineType,
    // Hardcoded value. DB doesn't store that property. Will be added in phase 2 (Donor Details page).
    disabled: true,
  })),
});
