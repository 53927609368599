import {MapTo} from '@matchsource/api-utils';
import {VaccinationModel, VaccinationStatus} from '@matchsource/models/vaccination';
import {VaccinationDto} from '@matchsource/api-generated/subject';

export const fromDTO: MapTo<VaccinationDto, VaccinationModel> = (dto: VaccinationDto): VaccinationModel => ({
  subjectGuid: dto.subjectGuid,
  type: dto.vaccCode,
  status: dto.vaccStatus as VaccinationStatus,
  date: dto.lastVaccDate,
  manufacturer: dto.vaccManufacturer,
  // Hardcoded value. DB doesn't store that property. Will be added in phase 2 (Donor Details page).
  selected: true,
});

export const toDTO: MapTo<VaccinationModel, VaccinationDto> = (model: VaccinationModel): VaccinationDto => ({
  subjectGuid: model.subjectGuid,
  vaccCode: model.type,
  vaccStatus: model.status,
  lastVaccDate: model.date || null,
  vaccManufacturer: model.manufacturer || null,
});
